<template>
  <div class="choice-page market-choice">
    <div class="title-md">
      {{ $t("nav-market") }}
    </div>

    <div class="market-choice__text">
      {{ $t("market-subtitle") }}
    </div>

    <div class="choice-page__list">
      <router-link to="swap-coins" tag="div" class="option">
        <div class="option__wrap">
          <div class="option__text">
            <div class="option__title">{{ $t("option-coins-title") }}</div>

            <div class="default-text">
              {{ $t("option-coins-caption") }}
            </div>
          </div>

          <div class="option__img">
            <img src="@/assets/img/coin-gold-img.png" alt="" />
          </div>

          <RoundedButton>
            <template v-slot:icon>
              <ArrowRight />
            </template>
          </RoundedButton>
        </div>
      </router-link>

      <router-link tag="div" to="market-character" class="option">
        <div class="option__wrap">
          <div class="option__text">
            <div class="option__title">{{ $t("option-char-title") }}</div>

            <div class="default-text">
              {{ $t("option-coins-caption") }}
            </div>
          </div>

          <div class="option__img">
            <img src="@/assets/img/svg/dummie-2.svg" alt="" />
          </div>

          <RoundedButton>
            <template v-slot:icon>
              <ArrowRight />
            </template>
          </RoundedButton>
        </div>
      </router-link>
    </div>

    <Rules
      :title="$t('rules-market-title')"
      :text="$t('rules-market-text')"
      @click="isRulesModal = true"
    />
    <GameRulesModal
      v-if="isRulesModal"
      @close="isRulesModal = false"
      :rules-title="$t('rules-market-title')"
    />
  </div>
</template>

<script>
import RoundedButton from "../../components/common/buttons/RoundedButton";
import ArrowRight from "../../components/icons/ArrowRight";
import Rules from "../../components/common/Rules";
import GameRulesModal from "../../components/modals/GameRulesModal";
export default {
  name: "MarketMain",
  data() {
    return {
      isRulesModal: false,
    };
  },
  components: { GameRulesModal, Rules, ArrowRight, RoundedButton },
};
</script>

<style scoped></style>
